import { TypeName } from './page-slot'

export class SocialIconsModel extends TypeName {
  constructor(item = null) {
    if (item) {
      super(item);
      this.name = item.name;
      this.icons = item.contents.map((icon) => {
        return new SocialIconsModelListItem(icon);
      })
    }
  }
}


export class SocialIconsModelListItem {
  constructor(item = null) {
    if (item) {
      this.socialNetworkName = item.socialNetworkName;
      this.socialUrl = item.socialUrl;
      this.socialIcon = item.socialIcon
      this.popUpIcon = item.popUpIcon
    }
  }
}

