import React from "react"
import Img from 'gatsby-image'
import PropTypes from "prop-types"

import "./video.scss"

class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isShowVideo: false };
    this.handlePlayVideo = this.handlePlayVideo.bind(this);
  }
  handlePlayVideo = (id) => {
    let myVideo = document.getElementById(id);
    this.setState({ isShowVideo: true })
    myVideo.play();
  }

  render() {
    const video = this.props.data;
    return (
      <div className="video-slot slot">
        <div className={this.state.isShowVideo ? 'video-slot__placeholder hide' : 'video-slot__placeholder'} onClick={() => this.handlePlayVideo(video.media.id)} >
          <Img
            className="video-slot__placeholder__image d-block w-100 "
            alt={video.title}
            key={video.media.id}
            fluid={video.thumbnail.fluid}
          />
          <div className="video-slot__placeholder__action">
            <div className="play-icon">
              <div className="carrot">&nbsp;</div>
            </div>
          </div>
          <div className="video-slot__placeholder__name">
            <h1 className="title">{video.title}</h1>
          </div>
        </div>
        <div className={this.state.isShowVideo ? 'video-slot__video active' : 'video-slot__video'}>
          <video controls preload="none" height="auto" id={video.media.id}>
            <source src={video.media.file.url} type="video/mp4" />
          </video>
        </div>
      </div>
    )
  }
}

Video.propTypes = {
  siteTitle: PropTypes.string,
}

Video.defaultProps = {
  siteTitle: ``,
}

export default Video
