/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Footer from "./footer/footer"
import Header from "./header/header"
import BackgroundImage from 'gatsby-background-image'
import "bootstrap/dist/css/bootstrap.min.css"
import "./layout.scss"
import en from 'react-intl/locale-data/en.js';
import zh from 'react-intl/locale-data/zh.js';
import { IntlProvider, addLocaleData } from 'react-intl'
import { Context } from './context'
import Provider from './provider'

addLocaleData([...en, ...zh])

const Layout = ({ children, bannerImage, pageTitle }) => {
  return (
    <Provider>
      <Context.Consumer>
        {({ lang }) => {
          return (
            <IntlProvider locale={lang}>
              <div className="">
                <div className="comvita-main-body">
                  <Header bannerImage={bannerImage} />
                  <div className={`${bannerImage ? 'comvita-bg-container' : ''}  `}
                    style={{
                      margin: `0 auto`,
                      paddingTop: bannerImage ? 0 : 95,
                    }}>
                    {bannerImage ? <BackgroundImage
                      className="comvita-page-header-container"
                      Tag={bannerImage ? bannerImage.title : ''}
                      fluid={bannerImage ? bannerImage.fluid : ''}
                    > <h1 className="display-1 text-capitalize text-comvita-shadow-heavy text-comvita-white comvita-page-title">{pageTitle}</h1></BackgroundImage>
                      : <></>}
                    <main>{children}</main>
                    <Footer></Footer>
                  </div>
                </div>
              </div>
            </IntlProvider>
          )
        }}
      </Context.Consumer>
    </Provider >
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
