import React from "react"
import Img from 'gatsby-image'
import PropTypes from "prop-types"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import CommonConstant from '../../shared/constants/common-constants';

import "./carousel.scss"

function Carousel({ data }) {
  // const dataSection = data;
  const Bold = ({ children }) => <span className="bold">{children}</span>
  // const Text = ({ children }) => <p className="align-center">{children}</p>

  const website_url = CommonConstant.Domain;
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className={'text-comvita-copy-brown'}>{children}</p>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { title, description, file } = node.data.target.fields;
        const mimeType = file['en-US'].contentType
        const mimeGroup = mimeType.split('/')[0]

        switch (mimeGroup) {
          case 'image':
            return <img
              title={title ? title['en-US'] : null}
              alt={description ? description['en-US'] : null}
              src={file['en-US'].url}
            />
          case 'application':
            return <a
              alt={description ? description['en-US'] : null}
              href={file['en-US'].url}
            >{title ? title['en-US'] : file['en-US'].details.fileName}
            </a>
          default:
            return <span style={{ backgroundColor: 'red', color: 'white' }}> {mimeType} embedded asset </span>
        }

      },

      [BLOCKS.HR]: (node, children) => <hr className={'comvita-hr'}></hr>,
      [BLOCKS.HEADING_1]: (node, children, parent) => <span className={'text-capitalize crousel-hero-metadata text-comvita-shadow-light text-comvita-dark-brown'}>{children}</span>,
      [BLOCKS.HEADING_2]: (node, children) => <h2 className={'text-uppercase text-comvita-copy-brown text-comvita-lighter'}>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3 className={'display-2 text-comvita-shadow-light text-comvita-dark-brown'}>{children}</h3>,
      [BLOCKS.HEADING_4]: (node, children) => <h4 className={'text-uppercase text-comvita-dark-brown'}>{children}</h4>,
      [BLOCKS.UL_LIST]: (node, children) => <ul className={'comvita-unordered-list'}>{children}</ul>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li className={'comvita-li-list-item'}>{children}</li>,
      [INLINES.HYPERLINK]: (node) => {
        return <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
      }
    },
  }


  const options2 = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className={'text-comvita-copy-brown'}>{children}</p>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { title, description, file } = node.data.target.fields;
        const mimeType = file['en-US'].contentType
        const mimeGroup = mimeType.split('/')[0]

        switch (mimeGroup) {
          case 'image':
            return <img
              title={title ? title['en-US'] : null}
              alt={description ? description['en-US'] : null}
              src={file['en-US'].url}
            />
          case 'application':
            return <a
              alt={description ? description['en-US'] : null}
              href={file['en-US'].url}
            >{title ? title['en-US'] : file['en-US'].details.fileName}
            </a>
          default:
            return <span style={{ backgroundColor: 'red', color: 'white' }}> {mimeType} embedded asset </span>
        }

      },

      [BLOCKS.HR]: (node, children) => <hr className={'comvita-hr'}></hr>,
      [BLOCKS.HEADING_1]: (node, children, parent) => <span className={'text-capitalize crousel-hero-metadata text-comvita-shadow-light text-comvita-white'}>{children}</span>,
      [BLOCKS.HEADING_2]: (node, children) => <h2 className={'text-uppercase text-comvita-copy-brown text-comvita-lighter'}>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3 className={'display-2 text-comvita-shadow-light text-comvita-white'}>{children}</h3>,
      [BLOCKS.HEADING_4]: (node, children) => <h4 className={'text-uppercase text-comvita-dark-brown'}>{children}</h4>,
      [BLOCKS.UL_LIST]: (node, children) => <ul className={'comvita-unordered-list'}>{children}</ul>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li className={'comvita-li-list-item'}>{children}</li>,
      [INLINES.HYPERLINK]: (node) => {
        return <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
      }
    },
  }

  if (data) {
    const dataCarousel = data.images;
    return (
      <div id="HeroCarousel" className="carousel slide slot carousel-fade" data-ride="carousel">
        <div className="carousel-inner">
          {dataCarousel.map((item, index) => (
            <div key={index} className={index === 0 ? 'carousel-item active' : 'carousel-item'}>
              <div>
                <div className={`${item.alignment ==='Left' ? 'align-comvita-left' : 'align-comvita-right'}  crousle-Data position-absolute`}>
                  <p className="hero-description">&nbsp;</p>
                  <p className="hero-description">&nbsp;</p>
                {item.isColorInverted ?   documentToReactComponents(item.richTextOverlay.json, options2) : documentToReactComponents(item.richTextOverlay.json, options)}
                </div>
                <Img
                  className="d-block w-100 background-image"
                  alt={item.name}
                  key={index}
                  fluid={item.image.fluid}
                  critical={index === 0}
                />
              </div>
            </div>
          ))}
        </div>
        <a className="carousel-control-prev" href="#HeroCarousel" role="button" data-slide="prev" aria-label="Previous">
          <span className="img-sprite comvita-carousel-left"></span>
        </a>
        <a className="carousel-control-next" href="#HeroCarousel" role="button" data-slide="next" aria-label="Next">
          <span className="img-sprite comvita-carousel-right"></span>
        </a>
      </div>)
  }
}

Carousel.propTypes = {
  siteTitle: PropTypes.string,
}

Carousel.defaultProps = {
  siteTitle: ``,
}

export default Carousel
