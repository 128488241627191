import React from 'react'
import { Context } from '../context'
import chinaFlag from '../../assets/icons/chinese-flag.png';
import newZealandFlag from '../../assets/icons/new-zealand-flag.png';

import "./change-language.scss"

const ChangeLanguage = () => (
  <Context.Consumer>
    {({ lang, toggleLanguage }) => {
      return (
        <li className="link-menu btn-change-language" style={{ whiteSpace: 'nowrap', }}>
          <a href="/" className="btn-change-language__link d-flex align-items-center align-items-center justify-content-end" onClick={(e) => {
            e.preventDefault();
            toggleLanguage();
          }}>
            <img src={lang === 'en' ? chinaFlag : newZealandFlag} className="langlogo" alt="Language Choice Flag"/>
            {lang === 'en' ? '中文' : 'English'}
          </a>
        </li>
      )
    }}
  </Context.Consumer>
)

export { ChangeLanguage }
