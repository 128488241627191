import { TypeName } from './page-slot'

export class CarouselModel extends TypeName {
  constructor(item = null) {
    super(item);
    this.images = item.images.map((image) => {
      return new CarouselImageModel(image);
    })
  }
}

export class CarouselImageModel {
  constructor(item = null) {
    if (item) {
      this.image = item.image;
      this.title = item.title;
      this.isColorInverted = item.colorInverted;
      this.alignment = item.alignment;
      this.richTextOverlay = item.richTextOverlay;
    }
  }
}