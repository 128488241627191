import React from 'react'
import { Context } from '../context'
import { Link } from "gatsby"

const LinkLocale = ({ className, children, url }) => {
  return (
    <Context.Consumer>
      {({ lang }) => {
        return <Link className={className} to={url + `/${lang}/`}>
          {children}
        </Link>
      }}
    </Context.Consumer>
  )
}

export { LinkLocale }
