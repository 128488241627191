import { TypeName } from './page-slot'

export class SectionModel extends TypeName {
  constructor(item = null) {
    super(item);
    this.image = item.image;
    this.imageWidth = item.imageWidth;
    this.imagePosition = item.imagePosition;
    this.backgroundClass = item.backgroundClass;
    this.title = item.title;
    this.subTitle = item.subTitle;
    this.linkUrl = item.linkUrl;
    this.linkDescription = item.linkDescription;
    this.body = item.body;
    this.bodyWidth = item.bodyWidth;
    this.sectionId = item.sectionId
    this.sectionHeading = item.sectionHeading;
  }
}

