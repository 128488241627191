import React from "react"
import Img from 'gatsby-image'
import PropTypes from "prop-types"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { LinkLocale } from "../link-locale/link-locale"
// import CommonConstant from '../../shared/constants/common-constants';

import "./section.scss"

function Section({ data }) {
  const dataSection = data;
  const Bold = ({ children }) => <span className="bold">{children}</span>
  // const Text = ({ children }) => <p className="align-center">{children}</p>

  // const website_url = CommonConstant.Domain;
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className={'text-comvita-copy-brown'}>{children}</p>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { title, description, file } = node.data.target.fields;
        const mimeType = file['en-US'].contentType
        const mimeGroup = mimeType.split('/')[0]

        switch (mimeGroup) {
          case 'image':
            return <img
              title={title ? title['en-US'] : null}
              alt={description ? description['en-US'] : null}
              src={file['en-US'].url}
            />
          case 'application':
            return <a
              alt={description ? description['en-US'] : null}
              href={file['en-US'].url}
            >{title ? title['en-US'] : file['en-US'].details.fileName}
            </a>
          default:
            return <span style={{ backgroundColor: 'red', color: 'white' }}> {mimeType} embedded asset </span>
        }

      },

      [BLOCKS.HR]: (node, children) => <hr className={'comvita-hr'}></hr>,
      [BLOCKS.HEADING_1]: (node, children) => <h1 className={'text-uppercase text-comvita-copy-brown text-comvita-lighter'}>{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2 className={'text-uppercase text-comvita-copy-brown text-comvita-lighter'}>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3 className={'text-comvita-gold display-3'}>{children}</h3>,
      [BLOCKS.HEADING_4]: (node, children) => <h4 className={'text-uppercase text-comvita-dark-brown'}>{children}</h4>,
      [BLOCKS.UL_LIST]: (node, children) => <ul className={'comvita-unordered-list'}>{children}</ul>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li className={'comvita-li-list-item'}>{children}</li>,
      [INLINES.HYPERLINK]: (node) => {
        let relStr = "";
        let targetSt = "";
        let re = /\[{2}.*\]{2}/;
        let rhtml = <a href={node.data.uri}>{node.content[0].value}</a>;
        var myArray = [];
        if (node.content[0].value) {
          myArray = node.content[0].value.match(re)
          if (myArray) {
            try {
              var obj = JSON.parse(myArray[0].replace("[[", "").replace("]]", ""));
              obj.map((attr) => {
                relStr = attr.key === "rel" ?  attr.value :  relStr;
                targetSt = attr.key === "target" ? attr.value :  targetSt;

                return attr
              })
  
              rhtml = <a href={node.data.uri} target={targetSt} rel={relStr}>{node.content[0].value.replace(myArray,"")}</a>;
            } catch (error) {}
          }
        }
        return rhtml;
      }
    },
  }
  const imPosition = dataSection.imagePosition ? dataSection.imagePosition[0] : "hidden";
  return (
    <div className={`slot ${dataSection.backgroundClass} `}>
      <div className={`comvita-section d-md-flex justify-content-between align-items-center ${imPosition === 'Right' ? 'flex-row-reverse' : ''} `}>

        {
          dataSection.image ? (<Img
            className={`comvita-section__image col-lg-${dataSection.imageWidth} ${dataSection.image != null ? 'hidden' : ''}`}
            alt={dataSection.image ? dataSection.image.title : ''}
            key={dataSection.image.id}
            fluid={dataSection.image.fluid}
          />) : <></>
        }


        <div className={`comvita-section__content ${dataSection.image  ? 'col-lg-' + dataSection.bodyWidth : (dataSection.bodyWidth > 0  ? 'col-lg-' + dataSection.bodyWidth :'col-sm-12')} ${dataSection.isPrimary ? 'text-center' : ''}`}>
        
        {
            dataSection.sectionHeading != null ?
              (<><h1 className="text-uppercase text-comvita-copy-brown text-comvita-lighter section-title">{dataSection.sectionHeading != null ? dataSection.sectionHeading : ''}</h1> <br /></>) : <></>
          }

          {
            dataSection.title != null ?
              (<><h2 className="text-uppercase text-comvita-copy-brown text-comvita-lighter section-title">{dataSection.title != null ? dataSection.title : ''}</h2> <br /></>) : <></>
          }
          {
            dataSection.subTitle ?
              <><h2 className="text-uppercase text-comvita-underline text-comvita-copy-brown">{dataSection.subTitle}</h2></> : <></>
          }
          {documentToReactComponents(dataSection.body.json, options)}

          {dataSection.linkUrl ? <LinkLocale url={dataSection.linkUrl} className="btn btn-comvita-sm btn-comvita-gold">{dataSection.linkDescription}</LinkLocale>
            : <></>}
          {/* <a className="btn btn-comvita-gold" href={dataSection.linkUrl}>{dataSection.linkDescription}</a> */}
          {/* linkUrl
              linkDescription */}
        </div>
      </div>
    </div>
  )
}

Section.propTypes = {
  siteTitle: PropTypes.string,
}

Section.defaultProps = {
  siteTitle: ``,
}

export default Section
