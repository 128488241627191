import React from "react"
import Img from 'gatsby-image'
import PropTypes from "prop-types"

import "./brand-home.scss"

function BrandHome({ data }) {
  if (data) {
    return (<div className="slot">
      <Img alt={data.image.description} fluid={data.image.fluid} />
    </div>)
  }
}

BrandHome.propTypes = {
  siteTitle: PropTypes.string,
}

BrandHome.defaultProps = {
  siteTitle: ``,
}

export default BrandHome
