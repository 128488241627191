import React from "react"
import PropTypes from "prop-types"
// import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
// import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
// import CommonConstant from '../../shared/constants/common-constants';

import "./faq.scss"


function Faq({ data }) {
  const dataFaq = data;
  // const Bold = ({ children }) => <span className="bold">{children}</span>
  // const Text = ({ children }) => <p className="align-center">{children}</p>
  // const website_url = CommonConstant.Domain;
  // const options = {
  //   renderMark: {
  //     [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  //   },  
  //   renderNode: {
  //     [BLOCKS.PARAGRAPH]: (node, children) => <p className={'text-comvita-copy-brown'}>{children}</p>,
  //     [BLOCKS.EMBEDDED_ASSET]: node => {
  //       let { description, title, file } = node.data.target.fields
  //       return <img className='img-fluid comvita-socialicons-inline-img' src={file["en-US"].url} alt={file["en-US"].title} />
  //     },
  //     [BLOCKS.HR]: (node, children) => <hr className={'comvita-hr'}></hr>,
  //     [BLOCKS.HEADING_1]: (node, children) => <h1 className={'text-uppercase text-comvita-copy-brown text-comvita-lighter'}>{children}</h1>,
  //     [BLOCKS.HEADING_2]: (node, children) => <h2 className={'text-uppercase text-comvita-copy-brown text-comvita-lighter'}>{children}</h2>,
  //     [BLOCKS.HEADING_3]: (node, children) => <h3 className={'text-comvita-gold display-3'}>{children}</h3>,
  //     [BLOCKS.HEADING_4]: (node, children) => <h4 className={'text-uppercase text-comvita-dark-brown'}>{children}</h4>,
  //     [BLOCKS.UL_LIST]: (node, children) => <ul className={'comvita-unordered-list'}>{children}</ul>,
  //     [BLOCKS.LIST_ITEM]: (node, children) => <li className={'comvita-li-list-item'}>{children}</li>,
  //     [INLINES.HYPERLINK]: (node) => {
  //       return <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
  //     }
  //   },
  // }

  if (dataFaq) {
    return (


      <div className="row text-center bg-comvita-grey comvita-footer-icons">
        <h1>{dataFaq.__typename}</h1>
        {/* {documentToReactComponents(dataFaq.answer.json, options)} */}
{/*        
        <div className="col-md-12">
          <div className="d-flex justify-content-center align-items-center">
            {data.icons.map((soc, index) => (
               soc.popUpIcon ?  <Popup trigger={<a target="_blank" href={soc.socialUrl} key={index} className="mx-2">
               <Img
                 className="mb-0 p-2 img-fluid"
                 alt={soc.socialIcon.title}
                 fluid={soc.socialIcon.fluid}
               />
             </a>} position="top center">
               <div>
                 {soc.popUpIcon ? <Img
                 className="mb-0 p-2 img-fluid footer-popUp"
                 alt={soc.popUpIcon.title}
                 fluid={soc.popUpIcon.fluid}
               /> : <></> }
                 </div>
             </Popup> : <a target="_blank" href={soc.socialUrl} key={index} className="mx-2"><Img
             className="mb-0 p-2 img-fluid"
             alt={soc.socialIcon.title}
             fluid={soc.socialIcon.fluid}
           />
           </a> 

            ))}
          </div>
        </div> */}
      </div>
    )
  }
}

Faq.propTypes = {
  siteTitle: PropTypes.string,
}

Faq.defaultProps = {
  siteTitle: ``,
}

export default Faq
