import { TypeName } from './page-slot'

export class CallToActionModel extends TypeName {
  constructor(item = null) {
    if (item) {
      super(item);
      this.title = item.title;
      this.subTitle = item.subTitle;
      this.content = new CallToActionModelList(item.content[0].listItems);
    }
  }
}

export class CallToActionModelList {
  constructor(listItems = null) {
    if (listItems) {
      /** 
      * @type CallToActionModelListItem[]
      */
      this.listItems = listItems.map((item) => {
        return new CallToActionModelListItem(item);
      })
    }
  }
}

export class CallToActionModelListItem {
  constructor(item = null) {
    if (item) {
      this.image = item.image;
      this.linkDescription = item.linkDescription;
      this.linkUrl = item.linkUrl;
    }
  }
}