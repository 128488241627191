import React from "react"
import PropTypes from "prop-types"
import Img from 'gatsby-image'
import { LinkLocale } from "../link-locale/link-locale"
import "./articles-list.scss"
  
function ArticlesList({ data }) {
  const articleslist = data;
  if (data) {
    return (

      <div className={`${articleslist.positionOfTitle !== null && articleslist.positionOfTitle !== undefined ? 'articles-list-heading' : ''}`}>
        <div className={`col-12 ${articleslist.positionOfTitle !== null && articleslist.positionOfTitle !== undefined ? 'text-' + articleslist.positionOfTitle.toLowerCase() : 'text-center'}`}>
          {articleslist.showTitle === true ? <h2 className="text-comvita-gold text-comvita-bold ">{articleslist.title}</h2> : <></>}
        </div>
        <div className="articles-list row">

          {/* {articleslist.articles.map((item, index) => {
            return ({item})
            }
          } */}
          {

            articleslist.articles.map((item, index) => {
              return (<div className="col-sm-4" key={index}>
                <div className="d-flex flex-column mb-5">
                  <LinkLocale url={`/${item.slug}`} >
                    <Img
                      className="img-fluid comvita-cta-image"
                      alt={item.title}
                      fluid={item.primaryImage.fluid}
                    />
                  </LinkLocale>
                  <LinkLocale url={`/${item.slug}`} >
                    <h4 className="text-comvita-dark-brown">{item.title}</h4>
                  </LinkLocale>
                  {articleslist.showPublishedDate === false ? <></> : <p className="text-comvita-copy-brown mt-3">{item.publishedDate}</p>}

                  <LinkLocale className="text-uppercase read-btn" url={`/${item.slug}`} >
                    {articleslist.showLink === false ? <></> : <div>Read ></div>}
                  </LinkLocale>
                </div>
              </div>
              )
            })
          }

        </div></div>
    )
  }
}

ArticlesList.propTypes = {
  siteTitle: PropTypes.string,
}

ArticlesList.defaultProps = {
  siteTitle: ``,
}

export default ArticlesList
