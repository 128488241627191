
import React from "react"
import Img from 'gatsby-image'
import PropTypes from "prop-types"

import "./slot-image.scss"

function SlotImage({ data }) {
  if (data) {
    return (<div className="slot">
       <Img
            className="slotimage-slot__placeholder__image d-block w-100 "
            alt={data.title}
            key={data.media.id}
            fluid={data.thumbnail}
          />
    </div>)
  }
}

SlotImage.propTypes = {
  siteTitle: PropTypes.string,
}

SlotImage.defaultProps = {
  siteTitle: ``,
}

export default SlotImage

