import React from "react"
import PropTypes from "prop-types"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
// import CommonConstant from '../../shared/constants/common-constants';
// import PcIcon from "../../images/pc.png"
// import MobileIcon from "../../images/mobile.png"
import { groupBy, values } from 'lodash';
import "./block-list.scss"

function BlockList({ data }) {
  data.blocks = data.blocks.sort(function (a, b) {
    return a.order - b.order;
  });


  let tmp = groupBy(data.blocks, 'sortingLetter');
  let uniqueLetters = data.blocks.map(item => item.sortingLetter).filter((value, index, self) => self.indexOf(value) === index && value != null);
  tmp = values(tmp);
  const Bold = ({ children }) => <span className="bold">{children}</span>
  // const Text = ({ children }) => <p className="align-center">{children}</p>
  // const website_url = CommonConstant.Domain;
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className={'text-comvita-copy-brown'}>{children}</p>,
      [BLOCKS.EMBEDDED_ASSET]: node => {
        let { file } = node.data.target.fields
        return <img className='img-fluid comvita-blocklist-inline-img' src={file["en-US"].url} alt={file["en-US"].title} />
      },
      [BLOCKS.HR]: (node, children) => <hr className={'comvita-hr'}></hr>,
      [BLOCKS.HEADING_1]: (node, children) => <h1 className={'text-uppercase text-comvita-copy-brown text-comvita-lighter'}>{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2 className={'text-uppercase text-comvita-copy-brown text-comvita-lighter'}>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3 className={'text-comvita-gold display-3'}>{children}</h3>,
      [BLOCKS.HEADING_4]: (node, children) => <h4 className={'text-uppercase text-comvita-dark-brown'}>{children}</h4>,
      [BLOCKS.UL_LIST]: (node, children) => <ul className={'comvita-unordered-list'}>{children}</ul>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li className={'comvita-li-list-item'}>{children}</li>,
      [INLINES.HYPERLINK]: (node) => {
        let relStr = "";
        let targetSt = "";
        let re = /\[{2}.*\]{2}/;
        let rhtml = <a href={node.data.uri}>{node.content[0].value}</a>;
        var myArray = [];
        if (node.content[0].value) {
          myArray = node.content[0].value.match(re)
          if (myArray) {
            try {
              var obj = JSON.parse(myArray[0].replace("[[", "").replace("]]", ""));
              obj.map((attr) => {
                relStr = attr.key === "rel" ?  attr.value :  relStr;
                targetSt = attr.key === "target" ? attr.value :  targetSt;
                return attr
              })
  
              rhtml = <a href={node.data.uri} target={targetSt} rel={relStr}>{node.content[0].value.replace(myArray,"")}</a>;
            } catch (error) { }
          }
        }
        //  rhtml = <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;

        // node.content[0].value === "PC" 
        // ? 
        // rhtml = <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}><img src={PcIcon} alt="PC Link" className="comvita-pc-link" /></a> 
        // : 
        // node.content[0].value === "Mobile" ? rhtml = <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}><img src={MobileIcon} alt="PC Link" className="comvita-pc-link" /></a> : rhtml = rhtml;
        return rhtml;
      }
    },
  }

  if (data) {
    return (
      <div>
        <div className="filter-block d-flex justify-content-around" id="block-list-group">
          {
            data.showLetterSorting ?
              uniqueLetters.sort().map((letter) => {
                return <a className="filter-block-item text-comvita-copy-brown" href={`#${letter}`}>{letter}</a>
              }) : <></>
          }

          {/* <a className="filter-block-item text-comvita-copy-brown" href="#B">B</a> */}
        </div>
        <div data-spy="scroll" data-target="#block-list-group" data-offset="0">
          {
            [...tmp].sort((a, b) => (a[0].sortingLetter > b[0].sortingLetter) ? 1 : -1).map((group, index) => {
              return (
                <div key={index} id={group[0].sortingLetter}>
                  {data.showLetterSorting ?
                    <div className="group-title text-comvita-gold">-------{group[0].sortingLetter}-------</div> : <></>
                  }

                  <div className="card-deck">
                    {
                      group.sort((a, b) => a.order - b.order).map((item, index) => {
                        return (
                          <div className="card mb-2" key={index}>
                            <div className="card-body">

                              {documentToReactComponents(item.body.json, options)}
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}

BlockList.propTypes = {
  siteTitle: PropTypes.string,
}

BlockList.defaultProps = {
  siteTitle: ``,
}

export default BlockList
