module.exports = {
  langs: ['zh', 'en'],
  defaultLangKey: 'zh',
  getCurrentLang: function () {
    try {
      return localStorage.getItem('lang') || 'zh';
    } catch (error) {
      return 'zh'
    }
  }
};