import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import "./header.scss"
import { ChangeLanguage } from "../change-language/change-language"
import { LinkLocale } from "../link-locale/link-locale"
import CommonConstant from '../../shared/constants/common-constants';
import { getCurrentLang } from '../../data/languages'
function openNav() {
  document.getElementById("menuHeader").classList.add("menu-open");
}

function closeNav() {
  document.getElementById("menuHeader").classList.remove("menu-open");
}
//Fixed Main Menu in both languages
const Header = ({ bannerImage }) => {
  const data = useStaticQuery(graphql`
  query MenuQuery {
    allContentfulMenu (filter: {name: {eq: "Main Menu"}}) {
      edges {
        node {
          node_locale
          menuItems {
            name
            menuUrl
            menuDescription {
              json
            }
            subItems {
              name
              menuUrl
              mobileDescription
              menuDescription {
                json
              }
              subItems {
                name
                menuUrl
                menuDescription {
                  json
                }
                subItems {
                  name
                  menuUrl
                  menuDescription {
                    json
                  }
                  subItems {
                    name
                    menuUrl
                    menuDescription {
                      json
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  `)
  const currentLanguage = getCurrentLang(); 
  const listMenu = data.allContentfulMenu.edges.filter(edge => edge.node.node_locale.search(currentLanguage) > -1)
  let menuItems = [];
  listMenu.map((item) => {
    menuItems = menuItems.concat(item.node.menuItems);
    return item;
  })
  const Bold = ({ children }) => <span className="bold">{children}</span>
  const website_url = CommonConstant.Domain;
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className={'text-comvita-copy-brown'}>{children}</p>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { title, description, file } = node.data.target.fields;
        const mimeType = file['en-US'].contentType
        const mimeGroup = mimeType.split('/')[0]

        switch (mimeGroup) {
          case 'image':
            return <img
              title={title ? title['en-US'] : null}
              alt={description ? description['en-US'] : null}
              src={file['en-US'].url}
            />
          case 'application':
            return <a
              alt={description ? description['en-US'] : null}
              href={file['en-US'].url}
            >{title ? title['en-US'] : file['en-US'].details.fileName}
            </a>
          default:
            return <span style={{ backgroundColor: 'red', color: 'white' }}> {mimeType} embedded asset </span>
        }

      },
      [BLOCKS.HR]: (node, children) => <hr className={'comvita-hr'}></hr>,
      [BLOCKS.HEADING_1]: (node, children) => <h1 className={'text-uppercase text-comvita-copy-brown text-comvita-lighter'}>{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2 className={'text-uppercase text-comvita-copy-brown text-comvita-lighter'}>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3 className={'text-comvita-gold display-3'}>{children}</h3>,
      [BLOCKS.HEADING_4]: (node, children) => <h4 className={'text-uppercase text-comvita-dark-brown'}>{children}</h4>,
      [BLOCKS.UL_LIST]: (node, children) => <ul className={'comvita-unordered-list'}>{children}</ul>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li className={'comvita-li-list-item'}>{children}</li>,
      [INLINES.HYPERLINK]: (node) => {
        return <a href={node.data.uri} target={`${node.data.uri.startsWith(website_url) ? '_self' : '_blank'}`} rel={`${node.data.uri.startsWith(website_url) ? '' : 'noopener noreferrer'}`}>{node.content[0].value}</a>;
      }
    },
  }

  let classHeader = [
    'd-flex justify-content-between header-container align-items-center',
    bannerImage ? 'bg-comvita-image' : ' bg-comvita-light-beige',
  ]
  classHeader = classHeader.join(' ')

  return (<header id="menuHeader" >
    <div id="overlayBg" className="overlay-bg" onClick={e => closeNav(e)}></div>
    <div className={classHeader}>
      <span id="menuToggle" className="menu-mobile-toggle text-comvita-gold my-auto position-absolute" onClick={e => openNav(e)}>&#9776;</span>
      <LinkLocale className={bannerImage ? 'logoWithSlog' : 'logoWithNoSlog'} url="/" aria-label="Comvita Home">
        <span className="non-transparent-item img-sprite logo-sprite">&nbsp;</span>
      </LinkLocale>
      <ul id="menuBar" className="sidenav">
        <ChangeLanguage classHeader={bannerImage ? 'bg-comvita-image' : ' bg-comvita-light-beige'} />
        {menuItems.map(link => (
          <li
            key={link.name}
            className="link-menu" style={{ whiteSpace: 'nowrap', }}
          >
            <LinkLocale url={link.menuUrl} >
              {link.name}
            </LinkLocale>
            <input type="checkbox" id={link.name + link.menuUrl} />
            {link.subItems && link.subItems.length > 0 ?

              <ul className="sub-menu">
                {
                  link.subItems.map(sub => (
                    <li
                      key={sub.name}
                      className="link-sub-menu"
                    >
                      <LinkLocale url={sub.menuUrl} >
                        <div className="link-sub-menu__pc">{documentToReactComponents(sub.menuDescription.json, options)}</div>
                        <div className="link-sub-menu__mobile">{sub.mobileDescription}</div>
                      </LinkLocale>

                      <input type="checkbox" id={link.name + link.menuUrl + sub.menuUrl} />
                    </li>
                  ))
                }
              </ul>
              : <></>}
          </li>
        ))}
      </ul>
    </div>
  </header>)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
