import PropTypes from "prop-types"
import React from "react"
import { LinkLocale } from "../link-locale/link-locale"
import { useStaticQuery, graphql } from "gatsby"
import "./footer.scss"
import Slot from "../../components/slot/slot"
import { SlotModel } from '../../shared/models/slot.model';
import { CarouselModel } from '../../shared/models/carousel.model';
import { SectionModel } from '../../shared/models/section.model';
import { CallToActionModel } from '../../shared/models/call-to-action.model';
import { VideoModel } from '../../shared/models/video.model';
import { ImageModel } from '../../shared/models/image.model';
import { SocialIconsModel } from '../../shared/models/social-icons.model';
import TypeSlot from '../../shared/constants/type-slot';
import { getCurrentLang } from '../../data/languages'

function Footer() {
  const currentLanguage = getCurrentLang();

  const data = useStaticQuery(graphql`
  query FooterMenuQuery {
    allContentfulFooter {
      nodes {
        node_locale
        slots {
          name
          __typename
          slotWidth
          backgroundClass
          contents {
            ... on ContentfulMenu {
              __typename
              id
              name
              menuItems {
                id
                menuUrl
                name
                cssClassName
                node_locale
              }
            }
            ... on ContentfulSlotSection {
              __typename
              title
              sectionId
              subTitle
              imagePosition
              isPrimary
              sectionHeading
              underlineHeader
              body {
                json
              }
              image {
                title
                file {
                  url
                  fileName
                  contentType
                }
                fluid {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              backgroundClass
              imageWidth
              bodyWidth
              linkUrl
            }
            ... on ContentfulSocialIconList {
              __typename
              name
              contents {
                socialNetworkName
                socialUrl
                socialIcon {
                  title
                  fluid {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
                popUpIcon{
                  title
                  fluid {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    allContentfulSocialIconList {
      nodes {
        name
        contents {
          socialUrl
          socialIcon {
            title
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        node_locale
      }
    }
  }
  `)

  const currentNode = data.allContentfulFooter.nodes.filter(node => node.node_locale.search(currentLanguage) > -1);
  const mainFooter = currentNode[0].slots[0];
  // const socialFooter = data.allContentfulSocialIconList.nodes.filter(node => node.node_locale.search(currentLanguage) > -1)[0];
  const listMenu = mainFooter.contents[0].menuItems;
  var slotsList = currentNode[0].slots;
  slotsList = slotsList.map(slot => {
    var slotObj = new SlotModel(slot);
    if (slotObj.content) {
      var contents = slotObj.content[0];
      switch (contents.__typename) {
        case TypeSlot.SlotCarousel:
          slotObj.content = new CarouselModel(contents)
          break;
        case TypeSlot.SlotSection:
          slotObj.content = new SectionModel(contents)
          break;
        case TypeSlot.SlotCTAProduct:
          slotObj.content = new CallToActionModel(contents)
          break;
        case TypeSlot.SlotVideo:
          slotObj.content = new VideoModel(contents)
          break;
        case TypeSlot.SlotImage:
          slotObj.content = new ImageModel(contents)
          break;
        case TypeSlot.SocialIconsModel:
          slotObj.content = new SocialIconsModel(contents)
          break;
        case TypeSlot.SlotBrand:
          break;
        default:
          break;
      }
    }
    return slotObj;
  })



  return (<footer id="menuFooter" className="footer-comvita bg-comvita-grey">
    <div>
      <div className="row text-center">
        {listMenu.map((link, index) => (
          <div className={`col-md ${link.cssClassName}`} key={index}>
            <LinkLocale className="text-uppercase text-comvita-dark-brown comvita-footer-localelink" url={link.menuUrl} >
              {link.name}
            </LinkLocale>
          </div>
        ))}
      </div>
     
        {slotsList.map((slot, index) => {
          return <Slot data={slot.content} type={slot.content.typeName} backgroundClass={`${slot.backgroundClass} ${slot.slotWidth ? 'col-lg-' + slot.slotWidth: ''}`} key={index} /> 
        })}
    
      {/* <div className="row text-center comvita-copyright-text">
        <div className="col-md-12">
          <div className="copyright-container">
            <p><img src={GovIcon} className="img-fluid comvita-icp-img" target="_blank" alt="ICP Icon"></img>
              ©2019 康維他有限公司及其附属公司 粤ICP备14096867号
                  </p>
            <p>版权所有，不得翻印。 ®™ 商标由康維他有限公司及其附属公司所拥有。</p>
          </div>
        </div>

      </div> */}
    </div>
  </footer>)
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
