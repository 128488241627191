
import PropTypes from "prop-types"
import React from "react"
import Carousel from "../carousel/carousel"
import BlockList from "../block-list/block-list"
import Section from "../section/section"
import Video from "../video/video"
import SlotImage from "../slot-image/slot-image";
import CallToAction from "../call-to-action/call-to-action";
import BrandHome from "../brand-home/brand-home";
import ArticlesList from "../articles-list/articles-list"

import TypeSlot from '../../shared/constants/type-slot';
import SocialIcons from "../social-icons/social-icons";
import Faq from "../faq/faq";

function Slot({ data, type, backgroundClass }) {
    // console.log("type:" + type)
    // console.log("data:")
    switch (type) {
        case TypeSlot.SlotCTAProduct:
            return (<section id={data.sectionId} className={backgroundClass}><CallToAction data={data} /></section>)
        case TypeSlot.SlotSection:
            return (<section id={data.sectionId} className={backgroundClass}><Section data={data} /></section>)
        case TypeSlot.SlotCarousel:
            return (<section id={data.sectionId} className={backgroundClass}><Carousel data={data} /></section>)
        case TypeSlot.SlotBrand:
            return (<section id={data.sectionId} className={backgroundClass}><BrandHome data={data} /></section>)
        case TypeSlot.SlotVideo:
            return (<section id={data.sectionId} className={backgroundClass}><Video data={data} /></section>)
        case TypeSlot.SlotImage:
            return (<section id={data.sectionId} className={backgroundClass}><SlotImage data={data} /></section>)
        case TypeSlot.SlotArticlesList:
            return (<section id={data.sectionId} className={backgroundClass}><ArticlesList data={data} /></section>)
        case TypeSlot.SocialIconsModel:
            return (<section id={data.sectionId} className={backgroundClass}><SocialIcons data={data} /></section>)
        case TypeSlot.FaqModel:
            return (<section id={data.sectionId} className={backgroundClass}><Faq data={data} /></section>)
        case TypeSlot.SlotBlockList:
            return (<section id={data.sectionId} className={backgroundClass}><BlockList data={data} /></section>)
        default:
            return (<div />)
    }
}

Slot.propTypes = {
    siteTitle: PropTypes.string,
}

Slot.defaultProps = {
    siteTitle: ``,
}

export default Slot
