import { TypeName } from './page-slot'

export class ImageModel extends TypeName {
  constructor(item = null) {
    super(item);
    this.title = item.title;
    this.thumbnail = item.image.fluid
    this.media = item.image;
  }
}
