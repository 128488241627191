const TypeSlot = {
  SlotCTAProduct: 'ContentfulSlotCallToAction',
  SlotUpdate: 'ContentfulSlotSection',
  SlotCarousel: 'ContentfulSlotCarousel',
  SlotBrand: 'ContentfulSlotSection',
  SlotVideo: 'ContentfulSlotVideo',
  SlotSection: 'ContentfulSlotSection',
  SlotImage: 'ContentfulSlotImage',
  SocialIconsModel: 'ContentfulSocialIconList',
  SlotFaq:'ContentfulSlotFaqQuestion',
  SlotBlockList:'ContentfulSlotBlocksList',
  SlotArticlesList:'ContentfulSlotArticlesArticleList',
};

export default TypeSlot;