import React from "react"
import Img from 'gatsby-image'
import PropTypes from "prop-types"

import "./call-to-action.scss"
import { LinkLocale } from "../link-locale/link-locale"

function CallToAction({ data }) {
  if (data) {
    return (<div className="slot">
      <h2 className="text-uppercase text-comvita-copy-brown">{data.title}</h2>
      <br />
      <h3 className="text-comvita-gold text-comvita-bold">{data.subTitle}</h3>
      <br />
      <div className="row">
        {data.content.listItems.map((item, index) => {
          return (<div className="col-lg" key={item.linkDescription}>
            <div className="d-flex flex-column mb-5">
              <Img
                className="mb-0 p-2 img-fluid comvita-cta-image"
                alt={item.image.title}
                fluid={item.image.fluid}
              />
              <LinkLocale url={item.linkUrl} className="btn btn-comvita-sm btn-comvita-gold-outline">{item.linkDescription}</LinkLocale>
            </div>
          </div>)
        })}
      </div>
    </div>)
  }
}

CallToAction.propTypes = {
  siteTitle: PropTypes.string,
}

CallToAction.defaultProps = {
  siteTitle: ``,
}

export default CallToAction
