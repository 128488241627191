import React from 'react'
import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { Context } from './context'

const Provider = ({ children, lang, toggleLanguage }) => {
	return (
		<Context.Provider
			value={{
				lang,
				toggleLanguage: () => toggleLanguage()
			}}>
			{children}
		</Context.Provider>
	)
}

const enhance = compose(
	withState('lang', 'handleLanguage', 'zh'),
	withHandlers({
		toggleLanguage: ({ lang, handleLanguage }) => () => {
			let newLang;
			
			if (lang === "zh") newLang = "en"
			else newLang = "zh";
				
			handleLanguage(newLang)
			localStorage.setItem('lang', newLang)
			const url = window.location.href;
			const newLink = url.replace(`/${lang}/`, `/${newLang}/`);
			window.location.replace(newLink);
		}
	}),
	lifecycle({
		componentDidMount() {
			const localLang = localStorage.getItem('lang')
			if (localLang) {
				this.props.handleLanguage(localLang)
			} else {
				this.props.handleLanguage(navigator.language.split('-')[0])
			}
		}
	})
)

export default enhance(Provider)
